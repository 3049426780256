import { cn } from "src/lib/utils";
import { IPlan } from "src/models";
import { Button } from "src/shadcn/ui/button";
import { CheckItem } from "src/components/common";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "src/shadcn/ui/card";

interface Props {
  plan: IPlan;
  currentPlanId: string;
  // eslint-disable-next-line no-unused-vars
  onSubscribeNow?: (planId: string, planType: string) => void;
}

const PlanCard = ({ plan, currentPlanId, onSubscribeNow }: Props) => {
  return (
    <Card
      className={cn(
        `w-72 flex flex-col justify-between py-1 border-zinc-700 mx-auto sm:mx-0 animate-background-shine bg-white dark:bg-[linear-gradient(110deg,#000103,45%,#1e2631,55%,#000103)] bg-[length:200%_100%] transition-colors p-4`,
      )}
    >
      <CardHeader className="pb-2 pt-4">
        <CardTitle className="text-zinc-700 dark:text-zinc-300 text-xl text-center">Team: {plan.name}</CardTitle>
        <div className="">
          <h3
            className={cn(
              "text-sm md:text-base text-center font-medium",
              plan?.type?.toUpperCase() === "ENTERPRISE" && "opacity-0",
            )}
          >
            ${plan.cost_per_month > 0 ? plan.cost_per_month : 0}/month
          </h3>
        </div>
      </CardHeader>
      <div>
        <CardContent className="flex flex-col gap-2 p-0 mt-2">
          <CheckItem text={`${plan.job_per_month === -1 ? "Unlimited" : plan.job_per_month} jobs per month`} />
          <CheckItem
            text={`${plan.document_per_month === -1 ? "Unlimited" : plan?.document_per_month} documents per month`}
          />
          <CheckItem text={`${plan.max_member === -1 ? "Unlimited" : plan.max_member} teams member(s)`} />
        </CardContent>

        <CardFooter className="mt-6 p-0">
          <Button
            variant={currentPlanId === plan.id ? "outline" : "default"}
            className="relative inline-flex w-full items-center justify-center rounded-md px-6 font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
            onClick={() => {
              if (onSubscribeNow) {
                onSubscribeNow(plan.id, plan.type);
              }
            }}
          >
            {currentPlanId === plan.id
              ? "View Current Plan"
              : plan?.type?.toUpperCase() !== "ENTERPRISE"
              ? "Subscribe Now"
              : "Contact Us"}
          </Button>
        </CardFooter>
      </div>
    </Card>
  );
};

export default PlanCard;
