import axios from "axios";

import { ILogin, IToken } from "src/models";
import api, { axiosClient } from "../api";
import { handleGlobalError } from "src/lib/utils";

export const createAccount = async (email: string, password: string) => {
  try {
    const res = await axiosClient.post("/auth/signup", { email, password });
    if (res.status === 200) {
      return "ok";
    }
    return "fail";
  } catch (error) {
    handleGlobalError(error);
    return "fail";
  }
};

export const confirmEmailAccount = async (email: string, code: string, invitationId: string) => {
  try {
    let payload: {
      email: string;
      code: string;
      invitation_id?: string;
    } = { email, code };

    if (invitationId) {
      payload = { email, code, invitation_id: invitationId };
    }

    const res = await axiosClient.post("/auth/email-confirmation", payload);

    if (res.status === 200) {
      return "ok";
    }
    return "fail";
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const resendEmailConfirm = async (email: string): Promise<string | undefined> => {
  try {
    const res = await axiosClient.post("/auth/resend-email-confirmation", {
      email,
    });
    return res?.data?.detail || "Resend code successful!";
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const loginAccount = async (credentials: { email: string; password: string }): Promise<ILogin | undefined> => {
  try {
    const res = await axiosClient.post("/auth/login", credentials);
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const forgotPassword = async (email: string): Promise<string | undefined> => {
  try {
    const res = await axiosClient.post("/auth/forgot-password", { email });
    return res?.data?.detail || "Password reset code sent to your email!";
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const resetPassword = async (
  reset_email: string,
  code: string,
  password: string,
): Promise<string | undefined> => {
  try {
    const res = await axiosClient.post("/auth/reset-password", {
      email: reset_email,
      code,
      password,
    });
    return res.data?.detail || "Change password successful";
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const logoutAccount = async () => {
  try {
    await api.post("/auth/logout");
  } catch (error) {
    handleGlobalError(error);
  }
};

export const getTokenByCode = async (code: string): Promise<IToken | undefined> => {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
    };

    // **staging **
    // const url = "https://test.auth.acquiretalent.co/oauth2/token";
    // const requestBody = `grant_type=authorization_code&code=${code}&client_id=5h00tgv485765k9ht2ueuend1f&redirect_uri=https://test.platform.acquiretalent.co/auth-callback`;

    // ** production **
    const url = "https://prod.auth.acquiretalent.co/oauth2/token";
    const requestBody = `grant_type=authorization_code&code=${code}&client_id=6bccv1gs20u80fquaebtviltcv&redirect_uri=https://platform.acquiretalent.co/auth-callback`;
    const res = await axios.post(url, requestBody, requestOptions);
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const refreshToken = async (refreshToken: string): Promise<IToken | undefined> => {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
    };

    // **staging **
    // const requestBody = `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=5h00tgv485765k9ht2ueuend1f`;

    // ** production **
    const requestBody = `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=6bccv1gs20u80fquaebtviltcv`;

    const res = await axios.post(`${process.env.REACT_APP_SOCIAL_URL}/oauth2/token`, requestBody, requestOptions);

    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};
